import { useEffect, useState } from 'react';
import Swiper, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
import 'swiper/modules/pagination/pagination.scss';
import { useLocation, useParams } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import gsap from 'gsap';
import $ from "jquery";
Swiper.use([Pagination, Navigation, Autoplay, EffectFade]);


function What() {
    const mobileYn = window.innerWidth > 900 ? false : true;
    const { id } = useParams();
    const scroll_ani_gap = window.innerWidth<900?400:800;

    const scrollEffect = function () {

        if(window.innerWidth<900){
            if($(window).scrollTop() >=300){
                $('.cate_menu').addClass('fixed');
            }else{
                $('.cate_menu').removeClass('fixed');
            }
           
        }
        if ($('.w2 .bs4').position()) {
            if ($(window).scrollTop() >= $('.w2 .bs5').position().top - scroll_ani_gap) {
                $('.cate_menu li').removeClass('active');
                $('.cate_menu li:nth-child(5)').addClass('active');
            }else if ($(window).scrollTop() >= $('.w2 .bs4').position().top - scroll_ani_gap) {
                $('.cate_menu li').removeClass('active');
                $('.cate_menu li:nth-child(4)').addClass('active');
            } else if ($(window).scrollTop() >= $('.w2 .bs3').position().top - scroll_ani_gap) {
                $('.cate_menu li').removeClass('active');
                $('.cate_menu li:nth-child(3)').addClass('active');
            } else if ($(window).scrollTop() >= $('.w2 .bs2').position().top - scroll_ani_gap) {
                $('.cate_menu li').removeClass('active');
                $('.cate_menu li:nth-child(2)').addClass('active');
            } else {
                $('.cate_menu li').removeClass('active');
                $('.cate_menu li:nth-child(1)').addClass('active');
            }
        }


    }
    useEffect(() => {
       
        if (id !== undefined) window.onload = () => { scroll_move_click(id); }

        gsap.from('.ani_t2', 1, {
            autoAlpha: 0,
            stagger: 0.2,


        })

        gsap.from('.poster_items .p_item', 1, {
            autoAlpha: 0,
            stagger: 0.2,

            scrollTrigger: {
                trigger: '.poster_items',
                start: "top +=" + window.innerHeight * 0.8,

            }
        })
       
        $(window).scroll(scrollEffect);

        const bs1_swiper = new Swiper('.bs1 .swiper', { 

            slidesPerView: window.innerWidth<900?1.9:(window.innerWidth>2000?5:4.5),
            spaceBetween:  window.innerWidth<900?16:60,
            slidesPerGroup:window.innerWidth<900?2:(window.innerWidth>2000?5:4),
            navigation: {
                disabledClass:'hide_bt',
                nextEl: '.next_bt', 
                prevEl: '.prev_bt', 
              },

           

        });
      
        const bs2_swiper = new Swiper('.bs2 .swiper', { 
            autoplay: {
                delay: 3000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,

        });
        const bs3_swiper = new Swiper('.bs3 .swiper', { 
            // Optional parameters
            autoplay: {
                delay: 3000,
            },
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
            },


        });
        const bs4_swiper1 = new Swiper('.bs4 .bsm_swiper1', { 
            // Optional parameters

            autoplay: {
                delay: 3000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,
        });
        const bs4_swiper2 = new Swiper('.bs4 .bsm_swiper2', {
            // Optional parameters

            autoplay: {
                delay: 3000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,
        });
        bs4_swiper2.autoplay.stop();
        setTimeout(() => {
            bs4_swiper2.autoplay.start();
        }, 500);

        return () => {

            $(window).unbind('scroll');
        }


    }, []);
    const scroll_move_click = (num) => {
        $('html, body').animate({ scrollTop: $('.bs' + num).position().top - (window.innerWidth<900?120:200) }, 1000);
    }
    return (
        <div className="contents_wrap">
            <div className="what w1">
                <div className='ani_t_line'>
                    <div className="section_title">
                        WHAT WE DO
                    </div>
                </div>

                <ul className='cate_menu'>
                    <li onClick={() => { scroll_move_click(1) }} className='active'>Contents</li>
                    <li onClick={() => { scroll_move_click(2) }}>Management</li>
                    <li onClick={() => { scroll_move_click(3) }}>Music</li>
                    <li onClick={() => { scroll_move_click(4) }}>New Media</li>
                    <li onClick={() => { scroll_move_click(5) }}>Academy</li>
                </ul>
            </div>
            <div className='what w2'>
                <div className='business_section bs1'>
                    <div className='bs_title ani_t2'><span className="t_kr">콘텐츠 사업</span><span className='t_en'>Contents</span></div>
                    <div className='bs_subtitle ani_t2'><span className="t_kr">글로벌 슈퍼 IP로 K-콘텐츠를 리드하다</span><span className='t_en'>Leading K-contents with Global Super IP</span></div>
                    <div className='bs_maincopy ani_t2'>
                        <span className="t_kr">
                        오리지널 스토리 IP 기획 개발부터 <br />
영화·드라마·OTT 영상 콘텐츠를 제작하는 <br />
NSENM의 핵심 사업입니다</span>
                        <span className='t_en'>
                        NSENM’s core business is content business : from planning and developing original story of IP to producing movies, soap opera, and OTT contents.
                        </span>
                        </div>
                    <div className='swiper poster_container'>
                   
                        <div className='navi_bt next_bt'>
                            <img src="/img/ic_arrow_right_bt.svg" alt="" />
                            <img className='active' src="/img/ic_arrow_right_bt_active.svg" alt="" />
                        </div>
                        <div className='navi_bt prev_bt'>
                            <img src="/img/ic_arrow_right_bt.svg" alt="" />
                            <img className='active' src="/img/ic_arrow_right_bt_active.svg" alt="" />
                        </div>
                       
                        <div className='swiper-wrapper poster_items'>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2021 미션 파서블</span><span className='t_en'>2021 MISSION: POSSIBLE</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-1.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2020 담보 </span><span className='t_en'>2020 Pawn</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-2.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2015 오늘의 연애</span><span className='t_en'>2015 Love Forecast</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-3.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2013 깡철이</span><span className='t_en'>2013 Tough As Iron</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-4.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2013 노브레싱</span><span className='t_en'>2013 No Breathing</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-5.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2012 반창꼬</span><span className='t_en'>2012 Love 911</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-6.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2010 황해</span><span className='t_en'>2010 The Yellow Sea</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-7.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2008 바보</span><span className='t_en'>2008 Ba:Bo</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-8.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2007 므이</span><span className='t_en'>2007 Muoi: The Legend of a Portrait</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-9.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2006 청춘만화</span><span className='t_en'>2006 Almost Love</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-10.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2006 아랑</span><span className='t_en'>2006 Arang</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-11.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2006 야수</span><span className='t_en'>2006 Running Wild</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-12.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2004 령</span><span className='t_en'>2004 Dead Friend</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-13.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2004 신석기 블루스</span><span className='t_en'>2004 Shin Suk-ki Blues</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-14.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2003 첫사랑 사수 궐기대회</span><span className='t_en'>2003 Crazy First Love</span></div>
                            </div>
                           
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-15.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2002 연애소설</span><span className='t_en'>2002 Lover's Concerto</span></div>
                            </div>
                            <div className='swiper-slide p_item'>
                                <div className='p_img'><img src="/img/Poster-16.jpg" alt="" /></div>
                                <div className='p_title'><span className="t_kr">2000 하면된다</span><span className='t_en'>2000 Just Do It</span></div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='business_section bs2'>
                    <div className='bs_title ani_t'><span className="t_kr">매니지먼트 사업</span><span className="t_en">Management</span></div>
                    <div className='bs_subtitle ani_t'><span className="t_kr">대한민국 TOP 아티스트, 엔에스이엔엠이 모이다</span><span className="t_en">Korea TOP artists gather at NSENM</span></div>
                    <div className='bs_maincopy ani_t'>
                        <span className="t_kr">체계적인 시스템을 구축하여 <br />
                        아티스트를 전문 관리하고 다양한 시도를 통해 <br />
                        성장할 수 있도록 비전을 제시합니다</span>
                        <span className="t_en">By establishing a systematic system, we manage artists professionally and present a vision for growth through various attempts.</span>
                        <div className='main_img ani_img'>
                            <div className='swiper bsm_swiper2'>
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=01.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=02.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=03.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=04.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=05.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=06.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=07.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=08.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=09.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=10.png"} alt="" /></div>
                                    <div className="swiper-slide"><img src={"/img/artist/Artist=11.png"} alt="" /></div>
                                </div>
                            </div>

                        </div>
                        <div className='bs_desc'>
                        <span className="t_kr">
                            - 아티스트와 장기적인 파트너쉽을 기반으로 지속 가능한 사업 추진<br />
                            - 아티스트가 성장할 수 있는 작품 선별 및 서포트<br />
                            - 소속 아티스트를 위한 맞춤형 스토리 IP 개발<br />
                            - 신인 아티스트 발굴 및 연기 아카데미 지원<br />
                            - 관계사들과의 협업을 통해 아티스트 활동 영역 확대
                            </span>
                        <span className="t_en">
                        - Promoting sustainable business based on long-term partnerships with artists<br />
                        - Selection and support for works where artists can grow<br />
                        - Development of customized Story IP for our artists<br />
                        - Discover new artists and support acting academy<br />
                        - Expanding artist activities through collaboration with affiliates
                        </span>
                        </div>
                    </div>
                </div>
                <div className='business_section bs3'>
                    <div className='bs_title ani_t'><span className="t_kr">뮤직 사업</span><span className="t_en">Music</span></div>
                    <div className='bs_subtitle ani_t'><span className="t_kr">OVER K-POP, 케이팝을 넘어</span><span className="t_en">OVER K-POP</span></div>
                    <div className='bs_maincopy ani_t'><span className="t_kr">소속 아티스트의 앨범 발매 및 정상급 프로듀싱 협업, <br />
                        다양한 영상, 패션, 플랫폼과의 협업을 통해 <br />
                        음악성과 수익성을 동시에 추구합니다
                        </span><span className="t_en">
                        We pursue musicality and profitability at the same time by releasing albums of our artists, cooperating with top-level producers, and collaborating with various videos, fashion, and platforms.
                        </span>
                        </div>
                    <div className='main_sl ani_section'>
                        <div className='swiper'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img src={"/img/what041" + (mobileYn ? '_m' : '') + ".png"} alt="" />
                                    <div className='ar_name'><span className="t_kr">김태우</span><span className="t_en">KIM TAE WOO</span></div>
                                </div>
                                <div className="swiper-slide">
                                    <img src={"/img/what042" + (mobileYn ? '_m' : '') + ".jpg"} alt="" />
                                    <div className='ar_name'><span className="t_kr">더원</span><span className="t_en">THE ONE</span></div>
                                </div>
                                <div className="swiper-slide">
                                    <img src={"/img/what043" + (mobileYn ? '_m' : '') + ".jpg"} alt="" />
                                    <div className='ar_name'> <span className="t_kr">앨리스</span><span className="t_en">ELRIS</span></div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>

                    </div>
                    <div className='bs_desc'>
                    <span className="t_kr">
                        - 소속 아티스트 앨범 발매<br />
                        - 신규 아티스트 발굴 및 육성 프로세스 체계화<br />
                        - 다양한 콘텐츠 플랫폼 및 패션 브랜드 콜라보레이션<br />
                        - 퍼블리싱 사업 글로벌 확대 및 세계적 프로듀서와의 협업
                        </span><span className="t_en">
                        - Releasing albums of our artists<br />
                        - Discover new artists and systematization of the training process<br />
                        - Collaboration with various contents platforms and fashion brands<br />
                        - Global expansion of publishing business and collaboration with world-class producers
                        </span>
                        <div className='bs_bt_section'>
                        </div>
                    </div>
                </div>
                <div className='business_section bs4'>
                    <div className='bs_title ani_t'> <span className="t_kr">뉴미디어 사업</span><span className="t_en">New Media</span></div>
                    <div className='bs_subtitle ani_t'> <span className="t_kr">새로운 미디어로의 확장, <br />
                        온-오프라인 통합 홍보 광고 프로그램 운영
                        </span><span className="t_en">Expansion into new media,<br />
Operating integrated on-offline promotional advertising programs.</span>
                        </div>
                    <div className='bs_maincopy ani_t'>
                    <span className="t_kr">
                        패션지와 인플루언서 파급효과를 융합한 <br />
                        「인+플랫폼」을 통하여 콘텐츠 제작, <br />
                        브랜드 홍보 사업을 전개하고 있습니다
                        </span><span className="t_en">
                        We are developing contents production and brand promotion business through an [In+Platform] that combines fashion magazines and influencer ripple effects.
                        </span>
                    </div>
                    <div className='bs_magazine_items ani_section'>
                        <div className='bsm_deco'><img src="/img/ic_plus_light.svg" alt="" /></div>
                        <div className='bsm_item'>
                            <div className='bsm_img'>
                                <div className='swiper bsm_swiper2'>
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <img src="/img/what0521.jpg" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="/img/what0522.jpg" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="/img/what0523.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='bsm_desc'>
                            <span className="t_kr">
                                인플루언서, 유튜버, 셀럽들을 통해 <br/>
                                새로운 기준의 아름다움을 담는 매거진
                                </span><span className="t_en">
                                A magazine that contains new standards of beauty through influencers, YouTubers and celebrities.

                                </span>
                                
                                </div>
                        </div>
                        <div className='bsm_deco_m'><img src="/img/ic_plus_m.svg" alt="" /></div>
                        <div className='bsm_item '>
                            <div className='bsm_img'>
                                <div className='swiper bsm_swiper1'>
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <img src="/img/what0511.jpg" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="/img/what0512.jpg" alt="" />
                                        </div>
                                        <div className="swiper-slide">
                                            <img src="/img/what0513.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bsm_desc'>
                            <span className="t_kr">
                                패션, 뷰티, 피트니스, 라이프 각 분야의 <br/>
                                인플루언서 네트워크로 광고 효과를 향상
                                </span><span className="t_en">
                                Improve advertising impact with influencer networks in each field of fashion, beauty, fitness, and life

                                </span>
                                </div>
                        </div>

                    </div>
                    <div className='bs_bt_section'>
                    <a href="http://www.magandjina.kr" rel="noreferrer" target={'_blank'}><button className='default_bt submit_bt'><span className="t_kr">맥앤지나 공식사이트</span><span className="t_en">MAG&JINA official website</span></button></a>
                    </div>
                   
                </div>
                <div className='business_section bs5'>
                    <div className='bs_title ani_t'> <span className="t_kr">아카데미 사업</span><span className="t_en">Academy</span></div>
                    
                    <div className='bs_maincopy ani_t'>
                    <span className="t_kr">
                    IOK 아카데미는 아티스트 매니지먼트 사업부를 <br/>
                    필두로 영화·드라마·OTT 등의 콘텐츠제작 및 <br/>
                    음반사업을 선도하는 엔에스이엔엠에서 <br/>
                    직접 설립하여 운영하는 전문 교육기관입니다.
                        </span><span className="t_en">
                        IOK Academy is a professional educational institution established and operated by NSENM, which leads content production such as movies, dramas, and OTT and recording businesses led by the artist management division.
                      </span>
                    </div>
                    <div className="bs5_img">
                        <img src="/img/about0501.jpg" alt="" />
                    </div>
                    <div className='bs_bt_section'>
                            <a href="https://iokacademy.com" rel="noreferrer" target={'_blank'}><button className='default_bt submit_bt'><span className="t_kr">IOK 아카데미 공식사이트  </span><span className="t_en">IOK Academy official website</span></button></a>
                    </div>
                </div>

            </div>
        </div>

    )


}


export default What;
