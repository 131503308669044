import { createElement, useEffect, useState } from 'react'; 
import { gsap } from 'gsap/all';
import { useLocation } from 'react-router-dom';
import { useAllPrismicDocumentsByType } from '@prismicio/react'
import $ from "jquery";
import Swiper, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper'; 
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
function Main() {
    const mobileYn = window.innerWidth > 900 ? false : true;
    const [news] = useAllPrismicDocumentsByType('news');
    const location = useLocation(); 
    useEffect(() => {
        $(".news_item").hover((e) => {
            $(document).mousemove((e) => {
                gsap.to($(e.target).find('.news_img'), 0, { css: { left: e.originalEvent.clientX, top: e.originalEvent.clientY } })
                gsap.to($(e.target).find('.news_img'), 0.5, { opacity: 1 })

            })


        }, () => {
            $(document).off('mousemove')
            gsap.to($('.news_img'), 0.5, { opacity: 0 })
        })

    }, [news])
    useEffect(() => {
        if(window.innerWidth<900){
            $('.m1').height(window.innerHeight);
        }


        const tl = gsap.timeline();
        tl.from(".ani_t_line2 > div", 1, { y: "15vw", stagger: 0.2 }, 0);
        tl.from(".m1 .main_copy img", 1, { opacity: 0 }, 1);
        tl.from(".m1 .main_copy .sub_copy_section", 1, { x: 10, autoAlpha: 0 }, 1.2);



        gsap.from('.m2', 1, {
            autoAlpha: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: '.m2',
                start: "top +=" + window.innerHeight * 0.8,

            }
        })
        const m2_swiper = new Swiper('.m2 .swiper', { 
            autoplay: {
                delay: 3000,
            },
            stagger: 0.1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            loop: true,

        });
        gsap.from(['.m2 .section_title', '.m2 .sub_copy_section'], 1, {
            autoAlpha: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: '.m2 .section_title',
                start: "top +=" + window.innerHeight * 0.8,

            }
        })
        gsap.from('.bs_item', 1, {
            autoAlpha: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: '.bs_item',
                start: "top +=" + window.innerHeight * 0.8,

            }
        })
        gsap.from('.news_header', 1, {
            autoAlpha: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: '.news_header',
                start: "top +=" + window.innerHeight * 0.8,

            }
        })
        gsap.from('.news_item', 1, {
            autoAlpha: 0,
            stagger: 0.2,
            scrollTrigger: {
                trigger: '.news_item',
                start: "top +=" + window.innerHeight * 0.8,

            }
        })



    }, [window.innerWidth])
    return (
        <div className="contents_wrap">
            <div className="main m1" style={{"backgroundImage":"url(/img/main0101"+(mobileYn?"_m":"")+".jpg)"}}>
                <div className="main_title">
                    <span className='big_t'>NSENM</span>
                </div>
                <div className='main_desc'>
                    
                <span className='t_kr'>엔에스이엔엠은 <div className="mobile_view"><br/></div>K-콘텐츠 시장을 리드하는 글로벌 엔터테인먼트 그룹입니다</span>
                <span className='t_en'>A global entertainment <div className="mobile_view"><br/></div>group leading <br/>the K-contents market</span>

                </div>
                <div className='bt_section'>
                <a href="/about"><button className='default_bt more_bt'>View More</button></a>
                </div>
            </div>
            <div className="main m2">

                <div className='main_img ani_t'>
                    <div className='swiper bsm_swiper2'>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide"><img src={"/img/artist/Artist=01.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=02.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=03.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=04.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=05.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=06.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=07.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=08.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=09.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=10.png"} alt="" /></div>
                            <div className="swiper-slide"><img src={"/img/artist/Artist=11.png"} alt="" /></div>
                         
                        </div>
                    </div>
                    <div className="iok_img"><img src={"/img/main01.svg"} alt="" /></div>
                </div>
                <div className="main_copy">
                    <div className="section_title">ARTIST</div>
                    <div className="sub_copy_section ">
                        <div className="sub_copy"><span className="empty_t"></span><span className="t_kr">NSENM은 아티스트의 재능과 개성을 부각시키고 신인 아티스트를 </span><span className='t_en'>NSENM leads K-contents through</span><br />
                        <span className="t_kr">발굴하여 다양한 채널에서의 활동을 지원합니다</span><span className='t_en'>various channels by discovering new artists and</span>
                       <span className='t_en'>publicising their talents and charms</span></div>
                        <div className="more_bt_section">
                            <a href="/artist"><button className="default_bt more_bt">View More</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main m3">
                <div className="business_items">
                    <a href="/what/1">
                        <div className="bs_item">

                            <div className="bs_num">01</div>
                            <div className="bs_title">CONTENTS</div>
                            <div className="bs_subtitle"><span className="t_kr">오리지널 IP K-콘텐츠 제작</span><span className='t_en'>Produce original IP K-contents</span><img src="/img/ic_arrow_right.svg" alt="" /></div>
                            <div className='bs_img'><img src="/img/main031.png" alt="" /></div>

                        </div>
                    </a>
                    <a href="/what/2">
                        <div className="bs_item">

                            <div className="bs_num">02</div>
                            <div className="bs_title">MANAGEMENT</div>
                            <div className="bs_subtitle"><span className="t_kr">대한민국 TOP 아티스트</span><span className='t_en'>Korea TOP Artists</span><img src="/img/ic_arrow_right.svg" alt="" /></div>
                            <div className='bs_img'><img src="/img/main032.png" alt="" /></div>
                        </div>
                    </a>
                    <a href="/what/3">
                        <div className="bs_item">
                            <div className="bs_num">03</div>
                            <div className="bs_title">MUSIC</div>
                            <div className="bs_subtitle"><span className="t_kr">케이팝을 넘어</span><span className='t_en'>Over K-POP</span><img src="/img/ic_arrow_right.svg" alt="" /></div>
                            <div className='bs_img'><img src="/img/main033.png" alt="" /></div>
                        </div>
                    </a>
                    <a href="/what/4">
                        <div className="bs_item">
                            <div className="bs_num">04</div>
                            <div className="bs_title">NEW MEDIA</div>
                            <div className="bs_subtitle"><span className="t_kr">새로운 미디어로의 확장</span><span className='t_en'>Expansion into new media</span><img src="/img/ic_arrow_right.svg" alt="" /></div>
                            <div className='bs_img'><img src="/img/main034.png" alt="" /></div>
                        </div>
                    </a>
                    <a href="/what/5">
                        <div className="bs_item">
                            <div className="bs_num">05</div>
                            <div className="bs_title">ACADEMY</div>
                            <div className="bs_subtitle"><span className="t_kr">대한민국 TOP 아티스트로 가는길</span><span className='t_en'>The path to becoming the top Korean artist</span><img src="/img/ic_arrow_right.svg" alt="" /></div>
                            <div className='bs_img'><img src="/img/main035.png" alt="" /></div>
                        </div>
                    </a>
                </div>
            </div>

            {false&&<div className="main m5">
                <div className="news_header">
                    <div className="section_title">LATEST NEWS</div>
                    <a href="/news"><div className="link_bt"><img src="/img/ic_plus_s.svg" alt="" /><img className='hover_img' src="/img/ic_plus_s_p.svg" alt="" />View All</div></a>
                </div>
                <div className="news_items">
                    {news && news.sort((function (a, b) {
                        return new Date(b.data.writen_date) - new Date(a.data.writen_date);
                    })).filter((ne, nIndex) => nIndex < 6).map((ne, nIndex) =>
                    (<a href={ne.data.original_link.url} rel="noreferrer" target={'_blank'} key={nIndex}>
                        <div className="news_item">
                            <div className="news_img" style={{ backgroundImage: "url(" + ne.data.thumnail.url + ")" }}></div>
                            <div className="news_date">{ne.data.writen_date}</div>
                            <div className="news_title">{ne.data.title[0].text}</div>
                        </div></a>))}

                </div>

            </div>}
        </div>

    )



}


export default Main;
