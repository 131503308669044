import { useEffect, useState } from 'react';
import gsap from 'gsap';

import { useLocation } from 'react-router-dom';
function Audition() {
    const mobileYn = window.innerWidth > 900 ? false : true;
    useEffect(() => {

    }, [])

    return (
        <div className="contents_wrap">
            <div className="audition">
                <div className='ani_t_line'>
                    <div className="section_title">
                        AUDITION
                    </div>
                </div>
                <div className='main_img ani_img'>
                    <img src={"/img/" + (mobileYn ? 'audition01_m' : 'main02') + ".jpg"} alt="" />
                </div>
                <div className='section_container'>
                    <div className="section_title">Audition</div>
                    <div className="section_contents">
                        <div className='sc_title ani_t'><span className="t_kr">NSENM이 열정 가득한 예비 아티스트를 기다립니다</span><span className='t_en'>NSENM is waiting for a passionate pre-artist</span> <br />
                            <span className="t_kr">여러분의 능력을 NSENM과 함께 펼쳐 보세요!</span><span className='t_en'>Unleash your talents with NSENM!</span></div>
                        <div className='sc_items'>
                            <div className='sc_item ani_t'>
                                <div className='sc_title'><span className="t_kr">모집 분야</span><span className='t_en'>Category</span></div>
                                <div className='sc_contents'>
                                    <span className="t_kr">연기자 · 가수 · 작가</span><span className='t_en'>Actor · Singer · Writer</span><br />
                                </div>
                            </div>
                            <div className='sc_item ani_t'>
                                <div className='sc_title'><span className="t_kr">모집 방법</span><span className='t_en'>Application <br />period</span></div>
                                <div className='sc_contents'>
                                    <span className="t_kr">수시 모집</span><span className='t_en'>Immediate Opening</span>
                                </div>
                            </div>
                            <div className='sc_item ani_t'>
                                <div className='sc_title'><span className="t_kr">전형 안내</span><span className='t_en'>Process guide</span></div>
                                <div className='sc_contents'>
                                    <span className="t_kr">이메일을 통한 1차 접수 후 합격자에 한해 2차 비공개 오디션이 진행됩니다.</span>
                                    <span className='t_en'>After the first application through e-mail, only the successful applicants will have a second private audition.</span><br />
                                    <ul className='subinfo'>
                                        <li><span className="t_kr">매월 1차 서류 전형 검토 후 합격자에 한해 개별 연락 드립니다.</span>
                                            <span className='t_en'>After reviewing the first application form every month, only the successful applicants will be contacted individually by phone.
                                            </span></li>
                                        <li><span className="t_kr">온·오프라인으로 접수된 모든 자료는 반환되지 않습니다.</span>
                                            <span className='t_en'>All materials submitted online or offline will not be returned.
                                            </span></li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div className='sc_item ani_t'>
                                <div className='sc_title'><span className="t_kr">제출 자료</span><span className='t_en'>Required <br />materials</span></div>
                                <div className='sc_contents'>
                                    <span className="t_kr">연기자 · 가수</span>
                                    <span className='t_en'>Actor · Singer</span><br />
                                    <ul className='subinfo reference_info'>
                                        <li><span className="t_kr">지원서</span>
                                            <span className='t_en'>Application form
                                            </span></li>
                                        <li><span className="t_kr">프로필 사진 3장 이상(얼굴 클로즈업, 전신 사진 포함, 과도한 보정 및 수정 사진 금지)</span>
                                            <span className='t_en'>3 or more profile photos(Including face-shots, full-body shots, No retouched photos)

                                            </span></li>
                                            <li><span className="t_kr">그 외 영상, 포트폴리오 등 자유 첨부</span>
                                            <span className='t_en'>Optionally attach other videos, portfolios, etc.
                                            </span></li>
                                            
                                       
                                    </ul>
                                    <br/>
                                    <span className="t_kr">작가</span>
                                    <span className='t_en'>Writer</span><br />
                                    <ul className='subinfo reference_info'>
                                        <li><span className="t_kr">지원서</span>
                                            <span className='t_en'>Application form
                                            </span></li>
                                        <li><span className="t_kr">경력이 기재된 자유 형식의 프로필 및 작품</span>
                                            <span className='t_en'>Free-form profile and work listing your work experience
                                            </span></li>
                                       
                                    </ul>
                                </div>
                            </div>
                            
                            <div className='sc_item ani_t'>
                                <div className='sc_title'><span className="t_kr">지원 방법</span>
                                    <span className='t_en'>How to apply</span></div>
                                <div className='sc_contents'>
                                    <span className="t_kr">하단의 지원서를 다운받아 작성한 후 제출 자료와 함께 아래의 이메일로 접수해주세요.</span>
                                    <span className='t_en'>Please download and fill out the application form below and send it to the email with required materials.</span><br />
                                    <br />
                                    <a href="https://prismic-io.s3.amazonaws.com/iokwebsite/bbc6e821-0c05-4784-995c-d67e05ec4d58_IOK_Audition.zip" download><button className='default_bt submit_bt'><span className="t_kr">지원서 다운로드</span><span className='t_en'>Download</span></button></a>
                                    <br />
                                    audition@iok.co.kr
                                </div>

                            </div>
                           
                            {false && <div className='sc_item'>
                                <div className='sc_title'>FAQ</div>
                                <div className='sc_contents'>
                                    <div className='faq_items'>
                                        <div className='faq_item active'>
                                            <div className='faq_header'>
                                                <div className='faq_title'>지원 자격은 누구나 가능한가요?</div>
                                                <div className='faq_bt'><img src="/img/ic_minus.svg" alt="" /></div>
                                            </div>
                                            <div className='faq_contents'>
                                                내용이들어갑니다.
                                            </div>
                                        </div>
                                        <div className='faq_item'>
                                            <div className='faq_header'>
                                                <div className='faq_title'>접수 방법과 접수 후 선발 과정은 어떻게 되나요?</div>
                                                <div className='faq_bt'><img src="/img/ic_plus.svg" alt="" /></div>
                                            </div>
                                            <div className='faq_contents'>
                                                내용이들어갑니다.
                                            </div>
                                        </div>
                                        <div className='faq_item'>
                                            <div className='faq_header'>
                                                <div className='faq_title'>신청서 작성시 유의해야 할 부분은 무엇인가요?</div>
                                                <div className='faq_bt'><img src="/img/ic_plus.svg" alt="" /></div>
                                            </div>
                                            <div className='faq_contents'>
                                                내용이들어갑니다.
                                            </div>
                                        </div>
                                        <div className='faq_item'>
                                            <div className='faq_header'>
                                                <div className='faq_title'>오디션 관련 문의는 어디로 하면 될까요?</div>
                                                <div className='faq_bt'><img src="/img/ic_plus.svg" alt="" /></div>
                                            </div>
                                            <div className='faq_contents'>
                                                내용이들어갑니다.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}


                        </div>
                    </div>

                </div>

            </div>
        </div>

    )



}


export default Audition;
