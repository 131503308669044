import { useEffect, useState } from 'react';
import { gsap, Linear } from 'gsap/all';
import Swiper, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { useLocation } from 'react-router-dom'; 
Swiper.use([Pagination, Navigation, Autoplay, EffectFade]);
function News() {
    const mobileYn = window.innerWidth > 900 ? false : true;
    const [currentCircleSlide, setCurrentCircleSlide] = useState(0);
    let bs_swiper;



    useEffect(() => {

        gsap.to('.a2 .bs_deco_t', 30, {
            x: -window.innerWidth * 3 + "px",
            repeat: 9999,
            ease: Linear.easeNone
        })
        bs_swiper = new Swiper('.a2 .swiper', {
            width: window.innerWidth < 900 ? window.innerWidth - 32 : 900,
            autoplay: {
                delay: 3000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            on: {
                slideChange: (e) => {
                    const bsBtItems = document.querySelector('.bs_bt_items');
                    gsap.to(bsBtItems, 0.2, { rotate: -(e.activeIndex) * 60 })

                }
            }

        });

    }, []);


    const bsClick = (bNum) => {
        //setCurrentCircleSlide(bNum);
        const bsBtItems = document.querySelector('.bs_bt_items');
        gsap.to(bsBtItems, 0.2, { rotate: -bNum * 60 })
        bs_swiper.slideTo(bNum, 500);

    }
    return (
        <div className="contents_wrap">
            <div className="about a1">
                <div className='ani_t_line'><div className="section_title">ABOUT US</div></div>

                <div className='main_img ani_img'>
                    <img src={"/img/main0101" + (mobileYn ? '_m2' : '') + ".jpg"} alt="" />
                </div>
                <div className='section_container'>
                    <div className="section_title">Overview</div>
                    <div className="section_contents ani_t">
                        <span className='t_kr'>
                            NSENM은 매니지먼트, 콘텐츠 제작, 뮤직, 뉴미디어, <br/> 
                            아카데미 등 K-contents 전 분야를 아우르는 <br/> 
                            글로벌 엔터테인먼트 그룹입니다</span>
                        <span className='t_en'>
                            NSENM Company is a global entertainment group that covers all areas of K-contents including management, contents production, music, new media, and academy.
                        </span>
                    </div>
                </div>
            </div>
            <div className="about a2">
                <div className='section_container'>
                    <div className="section_title">What We Do</div>
                    <div className="section_contents">

                        <div className='business_container ani_img'>
                            <div className='bs_deco_tri'><img src="/img/ic_tri_down.svg" alt="" /></div>
                            <div className='bs_img '>
                                <div className='swiper'>
                                    <div className='swiper-wrapper '>
                                        <div className='swiper-slide'><img src="/img/about021.jpg" alt="" /></div>
                                        <div className='swiper-slide'><img src="/img/about022.jpg" alt="" /></div>
                                        <div className='swiper-slide'><img src="/img/about023.jpg" alt="" /></div>
                                        <div className='swiper-slide'><img src="/img/about024.jpg" alt="" /></div>
                                        <div className='swiper-slide'><img src="/img/about025.jpg" alt="" /></div>
                                        <div className='swiper-slide'><img src="/img/about026.jpg" alt="" /></div>
                                    </div>
                                </div>

                            </div>
                            <ul className='bs_bt_items'>
                                <li onClick={() => { bsClick(0) }}><span className="t_kr">매니지먼트</span><span className="t_en">Management</span></li>
                                <li onClick={() => { bsClick(1) }}><span className="t_kr">콘텐츠</span><span className="t_en">Contents</span></li>
                                <li onClick={() => { bsClick(2) }}><span className="t_kr">뮤직</span><span className="t_en">Music</span></li>
                                <li onClick={() => { bsClick(3) }}><span className="t_kr">뉴미디어</span><span className="t_en">New media</span></li>
                                <li onClick={() => { bsClick(4) }}><span className="t_kr">아카데미</span><span className="t_en">Academy</span></li>
                                <li onClick={() => { bsClick(5) }}><span className="t_kr">신사업</span><span className="t_en">New business</span></li>
                            </ul>
                            <div className='bs_deco_circle'></div>
                        </div>
                       
                    </div>
                    <div className='bs_deco_t_wrap'>
                        <div className='bs_deco_t'>
                            INSIGHT OUTSTANDING K-CONTENTS INSIGHT OUTSTANDING K-CONTENTS
                        </div>
                        </div>
                </div>
            </div>
            {false && <div className='about a3' style={{ backgroundImage: 'url(/img/about03.jpg)' }}>
                <div className='section_container'>
                    <div className="section_title">History</div>
                    <div className="section_contents">
                        2000년 포인트아이닷컴(주)로 시작된 아이오케이는 2006년 코스닥 상장,
                        2015년 (주)아이오케이컴퍼니로 사명을 전환하였으며, 주요 콘텐츠사 및 엔터테인먼트 기업을
                        흡수하며 현재 미디어 전분야를 아우르는 사업 포트폴리오를 구축하고 있습니다.
                    </div>
                </div>
                <div className='timeline_items'>
                    <div className="tl_deco_line"></div>
                    <div className='tl_section'>

                        <div className='tl_item'>
                            <div className='tl_deco_circle'></div>
                            <div className='tl_t'>
                                <div className='tl_date'>2021.12</div>
                                <div className='tl_title'>킹엔터테인먼트 인수</div>
                            </div>

                        </div>
                        <div className='tl_item'>
                            <div className='tl_deco_circle'></div>
                            <div className='tl_t'>
                                <div className='tl_date'>2021.09</div>
                                <div className='tl_title'>YNK매니지먼트 합병</div>
                            </div>
                        </div>
                        <div className='tl_item'>
                            <div className='tl_deco_circle'></div>
                            <div className='tl_t'>
                                <div className='tl_date'>2021.03</div>
                                <div className='tl_title'>IHQ MOU</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='about a4'>
                <div className='section_container'>
                    <div className="section_title">Summary</div>
                    <div className="section_contents ani_t" >
                        <div className='info_item'>
                            <div className='info_title'><span className="t_kr">회사명</span><span className='t_en'>Company name</span></div>
                            <div className='info_contents'><span className="t_kr">주식회사 엔에스이엔엠 (NSENM)</span><span className='t_en'>NSENM</span></div>
                        </div>
                        <div className='info_item'>
                            <div className='info_title'><span className="t_kr">대표이사</span><span className='t_en'>CEO</span></div>
                            <div className='info_contents'><span className="t_kr">김민수 황최한초</span><span className='t_en'>Kim Min-soo Hancho Choi</span></div>
                        </div>
                        <div className='info_item'>
                            <div className='info_title'><span className="t_kr">설립일</span><span className='t_en'>Date of <br/>establishment</span></div>
                            <div className='info_contents'><span className="t_kr">2000년 4월</span><span className='t_en'>April, 2000</span></div>
                        </div>
                        <div className='info_item'>
                            <div className='info_title'><span className="t_kr">주요사업</span><span className='t_en'>Business field</span></div>
                            <div className='info_contents'><span className="t_kr">드라마, 영화 등 콘텐츠 제작, 연예 매니지먼트, 음반 및 음원 제작, 프로그램 공급</span>
                            <span className='t_en'>Contents production, Management, Music production, Supply programs</span></div>
                        </div>
                        <div className='info_item'>
                            <div className='info_title'><span className="t_kr">자본금</span><span className='t_en'>Capital</span></div>
                            <div className='info_contents'><span className="t_kr">24억(23년12월)</span><span className='t_en'>2.4 billion KRW (2023.12)</span></div>
                        </div>
                        <div className='info_item'>
                            <div className='info_title'><span className="t_kr">본사</span><span className='t_en'>Address</span></div>
                            <div className='info_contents'><span className="t_kr">서울특별시 용산구 서빙고로51길 52, 9층</span><span className='t_en'>52, Seobinggo-ro 51-gil, Yongsan-gu, Seoul, Republic of Korea</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='about a5'>
                <div className='section_container'>
                    <div className="section_title">인사말</div>
                    <div className="section_contents ani_t">
						NSENM은 체계적인 Artist Management와 더불어 Contents Creator 역량을 바탕으로 글로벌 종합 엔터테인먼트 기업을 만들고자 최선을 다합니다. 지속 성장하고 있는 Global Contents Market의 리더를 꿈꾸며 모든 주주, 임직원, 아티스트의 성공을 위해 노력합니다.<br /><br />
						방송 출연, 작품 선정, 모델 활동, 광고 등 매니지먼트 산업의 단순한 수익 모델에서 탈피해 아티스트의 장기적인 파트너 십을 기반으로 다양하고 지속 성장이 가능한 사업을 추진하고 있습니다. 현재 고현정, 조인성, 김하늘, 이성재, 신혜선, 김현주, 문채원, 김태우 등 40여명이 넘는 배우, 가수, 예능인 등 다양한 분야의 아티스트들에 대한 매니지먼트 사업을 확장해 나가고 있습니다.<br /><br />
						매니지먼트와 방송, 영상 콘텐츠 및 음원 사업 등을 통해 글로벌 종합 영상 콘텐츠 사업영역으로 확대하여 종합 엔터테인먼트로 도모할 예정입니다. 또한 오리지널 콘텐츠 프로젝트 진행과 신규사업으로 시즌제 콘텐츠 제작을 추진하고 있으며, 콘텐츠 다변화를 통해 규모의 성장, 실적 향상이 될 것으로 전망됩니다.

						<p>
							<b>김민수 황최한초</b><br />
							엔에스이엔엠 대표이사
						</p>
                    </div>
                </div>
            </div>
        </div>

    )



}


export default News;
